'use client'

import * as React from 'react'
import { Bar, BarChart, XAxis, YAxis } from 'recharts'

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  CardFooter,
} from '@/components/ui/card'
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { accidentData as chartData } from '@/lib/chart-data'

const chartConfig = {
  accidents: {
    label: 'Accidents',
  },
  fatal: {
    label: 'Fatal',
    color: 'hsl(var(--chart-1))',
  },
  possible_injury: {
    label: 'Possible Injury',
    color: 'hsl(var(--chart-2))',
  },
  suspected_serious_injury: {
    label: 'Suspected Serious Injury',
    color: 'hsl(var(--chart-3))',
  },
  no_injury: {
    label: 'No Injury',
    color: 'hsl(var(--chart-4))',
  },
  suspected_minor_injury: {
    label: 'Suspected Minor Injury',
    color: 'hsl(var(--chart-5))',
  },
} satisfies ChartConfig

const years = Object.keys(chartData).sort((a, b) => Number(b) - Number(a))

export function AccidentSeverityByYear() {
  const [activeYear, setActiveYear] = React.useState(years[0])

  const filteredData = chartData[activeYear]

  return (
    <Card className="flex h-full flex-col">
      <CardHeader className="flex-row items-start space-y-0 pb-0">
        <div className="grid gap-1">
          <CardTitle>Accident Severity along SR-347</CardTitle>
          <CardDescription>
            Select a year to view accident counts on SR-347, categorized by severity.
          </CardDescription>
        </div>
        <Select value={activeYear} onValueChange={setActiveYear}>
          <SelectTrigger
            className="ml-auto h-7 w-[130px] rounded-lg pl-2.5"
            aria-label="Select a year"
          >
            <SelectValue placeholder="Select year" />
          </SelectTrigger>
          <SelectContent align="end" className="rounded-xl">
            {years.map((year) => {
              return (
                <SelectItem key={year} value={year} className="rounded-lg">
                  {year}
                </SelectItem>
              )
            })}
          </SelectContent>
        </Select>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig}>
          <BarChart
            accessibilityLayer
            data={filteredData}
            layout="vertical"
            margin={{
              left: 16,
            }}
          >
            <YAxis
              dataKey="accidentType"
              type="category"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
            />
            <XAxis dataKey="accidents" type="number" hide />
            <ChartTooltip cursor={false} content={<ChartTooltipContent indicator="line" />} />
            <Bar dataKey="accidents" layout="vertical" radius={4} />
          </BarChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className="text-sm italic text-muted-foreground">
        Data collected from the Arizona Department of Transportation (ADOT) via a public records
        request.
      </CardFooter>
    </Card>
  )
}
