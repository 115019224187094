export const subjects = [
  '🚨 Urgent Action Needed: Safety Crisis on SR 347',
  'Fixing SR 347: A Matter of Life and Death for Commuters',
  '🚧 Gridlock and Danger: The Unacceptable Reality of SR 347',
  '⚠️ SR 347 in Crisis: We Must Act Now to Protect Drivers',
  'Thousands Impacted Daily by SR 347 Safety Hazards',
  '💔 Deadly Accidents on SR 347 Demand Your Attention',
  '📢 The SR 347 Emergency: Your Voice Can Make a Difference',
  'Maricopa Deserves Safe Roads: Solve the SR 347 Crisis',
  '⏳ Time is Running Out: SR 347 Needs Your Support Today',
  '🛠️ SR 347 Safety: The Urgency for Commuter-Focused Solutions',
  "SR 347: Maricopa's Dangerous Commute Demands Action Now",
  "Don't Let SR 347 Become a Deadly Trap - Take Action Today",
  '💪 Fixing SR 347 Can Save Lives - Will You Join the Fight?',
  '📢 SR 347 Crisis: Your Advocacy Can Make All the Difference',
  'Gridlock, Crashes, Tragedy - The Unacceptable Truth of SR 347',
  "⚠️ Driving on SR 347? Your Safety Is at Serious Risk - Here's How to Help",
  'The Unseen Dangers of SR 347 - Your Voice Can Bring Change',
  '🔒 Unlock a Safer Commute: Solutions for the SR 347 Emergency',
  'SR 347: A Critical Artery in Crisis - Your Urgent Attention Needed',
  'Maricopa Deserves Better Than the Nightmare of SR 347 - Take a Stand',
]
