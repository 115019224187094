'use client'

import * as React from 'react'
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from 'recharts'

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  CardFooter,
} from '@/components/ui/card'
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { accidentsByYearByMonth } from '@/data/accidents'

const chartConfig = {
  views: {
    label: 'Accidents',
  },
  accidents: {
    label: 'Accidents',
    color: 'hsl(var(--chart-5))',
  },
} satisfies ChartConfig

const years = Object.keys(accidentsByYearByMonth).sort((a, b) => Number(b) - Number(a))

export function AccidentsByYearByMonth() {
  const [activeYear, setActiveYear] = React.useState(years[0])

  const filteredData = accidentsByYearByMonth[activeYear]

  return (
    <Card>
      <CardHeader className="flex items-center gap-2 space-y-0 border-b py-5 sm:flex-row">
        <div className="grid flex-1 gap-1 text-center sm:text-left">
          <CardTitle>Yearly Breakdown of SR-347 Accidents</CardTitle>
          <CardDescription>
            View monthly accident trends for each year between 2014 and 2023.
          </CardDescription>
        </div>
        <Select value={activeYear} onValueChange={setActiveYear}>
          <SelectTrigger className="w-[160px] rounded-lg sm:ml-auto" aria-label="Select a year">
            <SelectValue placeholder="Select year" />
          </SelectTrigger>
          <SelectContent className="rounded-xl">
            {years.map((year) => {
              return (
                <SelectItem key={year} value={year} className="rounded-lg">
                  {year}
                </SelectItem>
              )
            })}
          </SelectContent>
        </Select>
      </CardHeader>
      <CardContent className="px-2 sm:p-6">
        <ChartContainer config={chartConfig} className="aspect-auto h-[250px] w-full">
          <BarChart
            accessibilityLayer
            data={filteredData}
            margin={{
              left: 12,
              right: 12,
              top: 20,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="month"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              minTickGap={32}
              tickFormatter={(value) => value.slice(0, 3)}
            />
            <YAxis tickLine={false} axisLine={false} />
            <ChartTooltip content={<ChartTooltipContent className="w-[150px]" nameKey="views" />} />
            <Bar dataKey="accidents" fill={`var(--color-accidents)`} radius={4} />
          </BarChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className="text-sm italic text-muted-foreground">
        Data collected from the Arizona Department of Transportation (ADOT) via a public records
        request.
      </CardFooter>
    </Card>
  )
}
