'use client'

import React, { useEffect, useRef } from 'react'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { ArrowRight } from 'lucide-react'
import { usePlausible } from 'next-plausible'

import { buttonVariants } from '@components/ui/button'
import './ticker.css'

const messages = [
  'Your voice matters: Contact 100+ state and ADOT officials now',
  'Make SR 347 safer: Fill out our contact form today',
  'Demand action for SR 347: Reach officials directly',
  'One form, 100+ officials: Amplify your concerns about SR 347',
  'Be heard: Your message will reach key decision-makers',
]

export default function ContactFormBanner() {
  const tickerRef = useRef<HTMLDivElement>(null)
  const pathname = usePathname()
  const plausible = usePlausible()

  useEffect(() => {
    const ticker = tickerRef.current
    if (!ticker) return

    const tickerWidth = ticker.offsetWidth
    const animationDuration = tickerWidth / 45 // Adjusted for readability

    ticker.style.animationDuration = `${animationDuration}s`
  }, [])

  if (pathname === '/contact') return null

  return (
    <div className="relative overflow-hidden border-b border-border bg-foreground text-background">
      <div className="mx-auto flex items-center justify-between px-1.5 py-3">
        <div className="mr-4 flex-1 overflow-hidden">
          <div ref={tickerRef} className="ticker-track inline-block whitespace-nowrap">
            {messages.concat(messages).map((message, index) => (
              <span key={index} className="mr-8 inline-flex items-center text-sm font-medium">
                {message}
              </span>
            ))}
          </div>
        </div>
        <div className="flex shrink-0 items-center gap-2">
          <Link
            href="/contact"
            onClick={() => {
              plausible('Banner CTA Clicked')
            }}
            className={buttonVariants({ variant: 'secondary', size: 'sm' })}
          >
            Act Now
            <ArrowRight className="ml-2 h-4 w-4" />
          </Link>
        </div>
      </div>
    </div>
  )
}
